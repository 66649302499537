@font-face {
    font-family: 'Vi';
    src: url('/Vi-Regular.woff') format('woff');
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Vi';
    src: url('/Vi-Medium.woff') format('woff');
    font-weight: medium;
}

@font-face {
    font-family: 'Vi';
    src: url('/Vi-SemiBold.woff') format('woff');
    font-weight: 500;
}

@font-face {
    font-family: 'Vi';
    src: url('/Vi-SemiBold.woff') format('woff');
    font-weight: 600;
}

@font-face {
    font-family: 'Vi';
    src: url('/Vi-Bold.woff') format('woff');
    font-weight: bold;
}