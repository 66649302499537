table,
th,
td {
	border: 1px solid black;
	border-collapse: collapse;
}

td {
	padding: "8px";
}
